export default [
  {
    title: 'Регистрация клиента',
    permission: 'clients.store',
    icon: 'UserPlusIcon',
    route: 'ClientCreate',
  },
  {
    title: 'Создать договор',
    permission: 'contracts.store',
    icon: 'FilePlusIcon',
    route: 'OrderCreate',
  },
  {
    title: 'Продать за наличные',
    permission: 'sell_for_cash',
    icon: 'DollarSignIcon',
    route: 'OrderCreateCash',
  },
  {
    title: 'Клиенты',
    permission: 'clients.index',
    icon: 'UsersIcon',
    route: 'ClientIndex',
  },
  {
    title: 'Эски шартномалар',
    permission: 'contracts.index',
    icon: 'FileTextIcon',
    route: 'OldOrdersIndex',
  },
  {
    title: 'Договора',
    permission: 'contracts.index',
    icon: 'FileTextIcon',
    route: 'OrdersIndex',
  },
  {
    title: 'Sudga oshirilganlar',
    permission: 'court-index',
    icon: 'AlertOctagonIcon',
    route: 'BroughtToCourt',
  },
  {
    title: 'Eski sudga oshirilgan shartnomalar',
    permission: 'court-index',
    icon: 'AlertOctagonIcon',
    route: 'OldBroughtToCourt',
  },
  {
    title: 'Оплаты',
    permission: 'payments.index',
    icon: 'DollarSignIcon',
    route: 'ConfirmPayment',
  },
  {
    title: 'Подтверждение',
    permission: 'payments.daily-index',
    icon: 'CheckSquareIcon',
    route: 'ConfirmPaymentDirector',
  },
  {
    title: 'Подтверждение договора',
    permission: 'contracts.update-images-confirmation-status',
    icon: 'ImageIcon',
    route: 'ConfirmContractPhotos',
  },
  {
    title: 'Товары мерчанта',
    permission: 'warehouses.has_in_warehouse_products',
    icon: 'ArchiveIcon',
    route: 'AllWarehouseProducts',
  },
  // {
  //   title: 'Отчеты',
  //   icon: 'BarChart2Icon',
  //   route: 'Main',
  // },
  // {
  //   title: 'Калькулятор',
  //   icon: 'DivideSquareIcon',
  //   route: 'Main',
  // },

  //
  {
    title: 'Склад',
    icon: 'LayersIcon',
    permission: 'warehouses.menu',
    route: 'ProvideresList',
    children: [
      // {
      //   title: 'Продукты',
      //   icon: 'SlackIcon',
      //   route: 'SettingsProduct',
      // },
      // {
      //   title: 'Yangi mahsulot turlari',
      //   icon: 'SlackIcon',
      //   permission: 'warehouses.product_categories-index',
      //   route: 'SettingsNewProductCategory',
      // },
      {
        title: 'Типы продуктов(n)',
        icon: 'SlackIcon',
        permission: 'warehouses.product_categories-index',
        route: 'SettingsNewProductCategory',
      },
      {
        title: 'Список складов',
        icon: 'LayersIcon',
        permission: 'warehouses.warehouses-index',
        route: 'SettingsWareHouse',
      },
      {
        title: 'Перемещение товаров',
        icon: 'RepeatIcon',
        permission: 'warehouses.product_transfer_parties-index',
        route: 'WarehouseTransferProduct',
      },
      {
        title: 'Поставщики',
        icon: 'TruckIcon',
        permission: 'warehouses.providers-index',
        route: 'ProvideresList',
      },
      {
        title: 'Optom klientlar',
        icon: 'UsersIcon',
        permission: 'warehouses.providers-index',
        route: 'BuyersList',
      },
      {
        title: 'Поступление товаров',
        icon: 'ClipboardIcon',
        permission: 'warehouses.parties-store',
        route: 'WarehousePartyCreate',
      },
      {
        title: 'Партии',
        icon: 'DatabaseIcon',
        permission: 'warehouses.parties-index',
        route: 'WarehouseParties',
      },
    ],
  },
  {
    title: 'Ёлланма ишчи',
    permission: 'settings',
    icon: 'UserPlusIcon',
    route: 'HiredWorkersList',
  },
  {
    title: 'Отчёты',
    permission: 'reports',
    icon: 'PieChartIcon',
    route: 'AllProductsReport',
  },
  {
    title: "Online to'lovlar",
    icon: 'BarChart2Icon',
    permission: 'uni_access.payments-index',
    route: 'AutopayList',
    children: [
      {
        title: 'Click tranzaksiyalar',
        permission: 'click_transactions.index',
        icon: 'CreditCardIcon',
        route: 'ClickTransactions',
      },
      {
        title: "Avtoto'lovlar",
        permission: 'uni_access.payments-index',
        icon: 'ZapIcon',
        route: 'AutopayList',
      },
      {
        title: "Avtoto'lov mijozlari",
        permission: 'uni_access.clients-index',
        icon: 'UsersIcon',
        route: 'AutopayClients',
      },
    ],
  },

  {
    title: 'Сканер продукта',
    permission: 'all',
    icon: 'CreditCardIcon',
    route: 'ScanerProduct',
  },
  
  {
    title: 'iCloud',
    icon: 'UploadCloudIcon',
    permission: 'iClouds.index',
    route: 'iCloudList',
  },
  {
    title: 'Knox',
    icon: 'ShieldIcon',
    permission: 'knoxes.index',
    route: 'KnoxesList',
  },
  {
    title: 'UzIMEI',
    icon: 'SlashIcon',
    permission: 'imei_devices.index',
    route: 'UzimeiList',
  },
  {
    title: '7Tech',
    icon: 'LockIcon',
    permission: 'seven_tech_devices.index',
    route: 'SevenTechList',
  },
  {
    title: 'Auksionlar',
    icon: 'GiftIcon',
    permission: 'marketplace.quick_orders-menu',
    route: 'AuctionsList',
  },
  {
    title: 'Buyurtmalar',
    icon: 'ShoppingBagIcon',
    permission: 'marketplace.quick_orders-menu',
    route: 'QuickOrdersList',
    tag: '2',
    tagVariant: 'danger',
  },
  // {
  //   title: 'Instagram',
  //   icon: 'InstagramIcon',
  //   permission: 'marketplace.quick_orders-menu',
  //   route: 'apps-chat',
  //   tag: '*',
  //   tagVariant: 'danger',
  // },
  {
    title: 'Telegram',
    icon: 'SendIcon',
    permission: 'marketplace.quick_orders-menu',
    route: 'telegram-chat',
    tag: '*',
    tagVariant: 'danger',
  },
  {
    title: 'Marketplace',
    icon: 'ShoppingCartIcon',
    permission: 'marketplace.menu',
    route: 'AutopayList',
    children: [
      // {
      //   title: 'Buyurtmalar',
      //   icon: 'ShoppingBagIcon',
      //   permission: 'marketplace.quick_orders-menu',
      //   route: 'QuickOrdersList',
      //   tag: '2',
      //   tagVariant: 'danger',
      // },
      {
        title: 'Marketplace foydalanuvchilari',
        icon: 'UserIcon',
        permission: 'marketplace.product_category_photos-menu',
        route: 'marketplaceUsersList',
      },
      {
        title: 'Marketplace banner',
        icon: 'ImageIcon',
        permission: 'marketplace.shop_gallery_images-menu',
        route: 'MarketplaceBannerCreate',
      },
      {
        title: 'Mahsulotlar foto',
        icon: 'ShoppingBagIcon',
        permission: 'marketplace.product_category_photos-menu',
        route: 'UploadProductPhotos',
      },
    ],
  },
  // {
  //   title: 'Investitsiya',
  //   icon: 'TrendingUpIcon',
  //   permission: 'settings.menu',
  //   route: 'Investors',
  //   children: [
  //     {
  //       title: 'Investorlar',
  //       permission: 'settings.users-index',
  //       icon: 'UsersIcon',
  //       route: 'Investors',
  //     },
  //   ],
  // },
  {
    title: 'Настройка',
    icon: 'SettingsIcon',
    permission: 'settings.menu',
    route: 'SettingsRegion',
    children: [
      {
        title: 'Регионы',
        permission: 'settings.region-index',
        icon: 'SettingsIcon',
        route: 'SettingsRegion',
      },
      // {
      //   title: 'Склад',
      //   icon: 'SettingsIcon',
      //   route: 'SettingsWareHouse',
      // },
      {
        title: 'Пользователи',
        permission: 'settings.users-index',
        icon: 'UsersIcon',
        route: 'SettingsUsers',
      },
      {
        title: 'Роли',
        permission: 'settings.roles-index',
        icon: 'UserCheckIcon',
        route: 'SettingsRoles',
      },
      {
        title: 'Harf biriktirish',
        permission: 'settings.levy_user_letters-index',
        icon: 'TypeIcon',
        route: 'SettingsAttachLetterToUsers',
      },
      {
        title: 'Raqam biriktirish',
        permission: 'settings.levy_user_letters-index',
        icon: 'HashIcon',
        route: 'SettingsAttachNumberToUsers',
      },
      {
        title: 'Смс шаблоны',
        permission: 'settings.sms_templates-index',
        icon: 'MailIcon',
        route: 'SettingsSmsTemplate',
      },
      {
        title: 'Смс юбориш',
        permission: 'sms.send-sms-messages',
        icon: 'SendIcon',
        route: 'SettingsSmsSend',
      },
      {
        title: 'Ўлчов бирликлари',
        permission: 'settings.unit_of_measures-index',
        icon: 'SlackIcon',
        route: 'MueasuresList',
      },
      {
        title: 'Системные настройки',
        permission: 'settings.settings-index',
        icon: 'SettingsIcon',
        route: 'setting-index',
      },
      {
        title: 'Валюта курси',
        permission: 'settings.settings-index',
        icon: 'DollarSignIcon',
        route: 'DollarRates',
      },
      {
        title: 'Telefon kodlari',
        permission: 'settings.phone-codes',
        icon: 'MessageSquareIcon',
        route: 'PhoneCodes',
      },
    ],
  },
  {
    title: 'Narxlarini chop etish',
    icon: 'PrinterIcon',
    permission: 'warehouses.has_in_warehouse_products',
    route: 'SettingsProductPrint',
  },
  {
    title: "Qora ro'yhat",
    icon: 'AlertTriangleIcon',
    permission: 'settings.blacklists-index',
    route: 'BlackListClients',
  },
  {
    title: 'Takliflar',
    icon: 'InfoIcon',
    permission: 'suggestions.index',
    route: 'SuggestionsList',
  },
  {
    title: 'Vazifalar',
    permission: 'all',
    icon: 'ListIcon',
    route: 'Tasks',
  },
  {
    title: 'KPI',
    permission: 'kpi',
    icon: 'ListIcon',
    route: 'KpiEmployers',
  },
]
